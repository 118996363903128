import React from "react"
import "./src/assets/fonts/fonts.css"
import PageWrapper from "./src/components/PageWrapper"
import smoothscroll from "smoothscroll-polyfill"

smoothscroll.polyfill()

export const wrapPageElement = ({ element, props }) => {
  return <PageWrapper {...props}>{element}</PageWrapper>
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  location.previous = prevLocation ? prevLocation.pathname : null
}
