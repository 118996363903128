import React, { useEffect } from "react"
import * as S from "./styled"
import { Body3, MenuLink } from "../Texts"
import { Link } from "../../../.cache/commonjs/gatsby-browser-entry"
import { graphql, useStaticQuery } from "gatsby"
import { urlFor } from "../../util/url"
import DatoCmsText from "../DatoCmsText"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

const MobileMenu = ({ open }) => {
  const { brands, contact, contacts } = useStaticQuery(graphql`
    {
      contact: datoCmsContactDetail {
        address
        email
        socialMedia {
          url
          name
        }
      }
      contacts: allDatoCmsContact {
        edges {
          node {
            email
            name
            phone
          }
        }
      }
      brands: allDatoCmsBrand {
        edges {
          node {
            __typename
            name
            slug
          }
        }
      }
    }
  `)
  useEffect(() => (open ? disableBodyScroll() : enableBodyScroll()), [open])
  return (
    <S.Overlay visible={open}>
      <S.Menu>
        <MenuLink as={Link} to="/#what-we-do">
          What we do
        </MenuLink>
        <MenuLink as="p" style={{ marginTop: "35px" }}>
          Brands
          {brands.edges.map(({ node: { __typename, name, slug } }) => (
            <React.Fragment key={name}>
              <br />
              <MenuLink
                className="brand"
                key={name}
                as={Link}
                to={urlFor(__typename, slug)}
              >
                {name}
              </MenuLink>
            </React.Fragment>
          ))}
        </MenuLink>
        <MenuLink as={Link} to="/#about-us">
          About us
        </MenuLink>
      </S.Menu>
      <S.Divider />
      <S.Grid>
        <DatoCmsText text={contact.address} WrapperComponent={Body3} />
        <Body3>
          <a href={`mailto:${contact.email}`}>{contact.email}</a>
          <br />
          <br />
          {contact.socialMedia.map(({ url, name }) => (
            <a key={name} href={url}>
              {name}
            </a>
          ))}
        </Body3>
      </S.Grid>
      <S.Divider />
      <S.Grid>
        {contacts.edges.map(({ node: { name, email, phone } }) => (
          <Body3 key={name}>
            {name}
            <br />
            <a href={`mailto:${email}`}>{email}</a>
            <br />
            {phone}
          </Body3>
        ))}
      </S.Grid>
    </S.Overlay>
  )
}

export default MobileMenu
