import styled from "styled-components"
import device from "../util/devices"

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 10px;
  margin-left: auto;
  margin-right: auto;
  width: clamp(290px, 84.1vw, 1340px);

  @media (${device.tablet}) {
    grid-column-gap: 20px;
  }

  @media (${device.desktop}) {
    grid-template-columns: repeat(12, 1fr);
  }
`

export default GridLayout
