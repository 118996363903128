import styled from "styled-components"

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #e5e5e5;
  z-index: 40;
  padding: 80px 0 100px;
  display: flex;
  transition: opacity 450ms ease-out;

  &.fading {
    opacity: 0;
  }
`

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  img {
    height: auto;
    width: clamp(195px, 55vw, 278px);
  }
`
