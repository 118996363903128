import styled from "styled-components"

export const Body1 = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
`

export const Body2 = styled.div`
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
`

export const Title1 = styled.div`
  font-family: "Chap", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
`

export const Body3 = styled.div`
  font-size: 13px;
  line-height: 20px;
`

export const ImageCaption = styled.div`
  padding-top: 5px;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: #b0b0b0;
  transition: all 150ms ease-out;
`

export const BrandTitle = styled.div`
  font-family: "Chap", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 29px;
`

export const MenuLink = styled.div`
  font-weight: normal;
  font-size: 26px;
  line-height: 33px;
  text-decoration: none;
  color: inherit;
  transition: color 150ms ease-out;

  &.brand {
    font-family: "Chap", serif;
    color: #b0b0b0;

    &:hover {
      color: inherit;
    }
  }
`

export const Name = styled.span`
  font-weight: normal;
  font-size: 22px;
  line-height: 22px;
`
