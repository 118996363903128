import React from "react"

const defaultValue = {
  linearBackgroundColors: null,
  setLinearBackgroundColors: () => {},
}

const BackgroundContext = React.createContext(defaultValue)

BackgroundContext.default = defaultValue

export default BackgroundContext
