import styled, { css } from "styled-components"
import device from "../../util/devices"

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: #e5e5e5;
  padding-top: 71px;
  overflow: auto;
  transition: opacity 250ms ease-out;

  ${props =>
    props.visible
      ? css`
          opacity: 1;
          z-index: 20; // So it stays "below" the header"
        `
      : css`
          opacity: 0;
          z-index: -20;
        `};

  @media (${device.desktop}) {
    display: none;
  }
`

export const Menu = styled.div`
  padding-left: 12px;
`

export const Divider = styled.hr`
  border: 1px solid black;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  padding: 45px 10px 20px;

  > * {
    white-space: pre-wrap;
    a {
      color: inherit;
      :not(:first-child) {
        text-decoration: none;
      }
    }
  }

  ${[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
    i => css`
      > :nth-child(${i}) {
        grid-column: ${i % 2 === 1 ? 1 : 2};
        grid-row: ${Math.ceil(i / 2)};
      }
    `
  )}
`
