import styled, { css } from "styled-components"
import GridLayout from "../../layout/GridLayout"
import device from "../../util/devices"
import { Body3, ImageCaption } from "../Texts"
import DatoCmsText from "../DatoCmsText"

export const Overlay = styled(GridLayout)`
  background: rgba(98, 98, 96, 0.6);
  position: fixed;
  width: 100% !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  align-content: center;
  justify-items: center;
  z-index: 40;
  margin: 0;
`

export const Body = styled(GridLayout)`
  background: white;
  grid-column: 1 / 7;
  max-width: 980px;
  @media (${device.tablet}) {
    grid-column: 2 / 6;
  }

  @media (${device.desktop}) {
    grid-column: 3 / 11;
    grid-template-columns: repeat(8, 1fr);
  }
`

export const Logo = styled.img`
  grid-column: 3 / 5;
  max-width: 100%;
  grid-row: 1;
  margin: 0 auto;
  padding-top: 20px;

  @media (${device.desktop}) {
    grid-column: 4 / 6;
  }
`

export const CloseButton = styled(ImageCaption)`
  color: inherit;
  grid-column: 6;
  padding-top: 20px;
  padding-right: 20px;
  text-align: right;
  grid-row: 1;
  cursor: pointer;
  @media (${device.desktop}) {
    grid-column: 8;
    margin-left: auto;
    padding-top: 30px;
  }
`

export const Divider = styled.hr`
  grid-column: 1 / 7;
  grid-row: ${props => props.row};
  border: none;
  border-bottom: 1px solid black;
  width: 100%;

  @media (${device.desktop}) {
    grid-column: 1 / 9;
    &:first-of-type {
      margin-top: 85px;
      margin-bottom: 45px;
    }
  }
`

export const Address = styled(DatoCmsText)`
  grid-row: 3;
  padding-left: 20px;
  grid-column: 1 / 4;
  white-space: pre-wrap;
  p {
    margin: 0;
  }

  @media (${device.desktop}) {
    grid-column: 1 / 3;
  }
`

export const EmailAndSocialMedia = styled(Body3)`
  grid-row: 3;
  grid-column: 4 / 7;

  @media (${device.desktop}) {
    grid-column: 3 / 6;
  }

  a {
    color: inherit;
    :not(:first-child) {
      text-decoration: none;
    }
  }
`

export const FiscalInfo = styled(Body3)`
  grid-row: 4;
  grid-column: 1 / 7;
  padding-left: 20px;
  margin-top: 10px;
  padding-top: 10px;
  @media (${device.desktop}) {
    grid-row: 3;
    grid-column: 7 / 9;
    padding: 0;
    margin: 0 0 0 -20px;
  }
  p {
    margin: 0;
  }
`

export const Contact = styled(Body3)`
  grid-row: 8;
  
  a {
    color: inherit;
  }
  
  ${[1, 2].map(
    i => css`
      :nth-of-type(${i}) {
        grid-column: 1 / 7;
        grid-row: ${4 + i};
        padding-left: 20px;
      }
    `
  )}
  
  @media (${device.desktop}) {
  :first-of-type {
    margin-left: 20px;
  }
  ${[1, 2].map(
    i => css`
      :nth-of-type(${i}) {
        padding-left: 0;
        grid-row: 5;
        grid-column: ${1 + (i - 1) * 2} / ${3 + (i - 1) * 2};
      }
    `
  )};
  }
`
