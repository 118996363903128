import styled, { css } from "styled-components"

export const FooterWrapper = styled.div``

export const FooterLines = styled.div`
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding-top: clamp(64px, ${(64 / 390 * 100).toFixed(2)}vw, 104px);
  padding-bottom: 24px;
  @media (max-width: 767px) {
    > div {
      grid-row-gap: 44px;
    }
  }
`

export const FooterColumn = styled.div`
  grid-column: span 3;
  display: flex;
  flex-direction: column;
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
`

export const Legal = styled.div`
  grid-column: 1 / 13;
  color: hsla(0, 0%, 75%, 1);
  font-size: 12px;
  padding-top: clamp(22px, ${(22 / 390 * 100).toFixed(2)}vw, 30px);
  padding-bottom: 1em;
`