import React, { useState } from "react"
import { graphql, Link, navigate, useStaticQuery } from "gatsby"
import * as S from "./styled"

import SVG from "react-inlinesvg"
import ContactUsModal from "../ContactUsModal"

const Header = ({ light, backOnly, menuOpen, setMenuOpen, sticky, onBack }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const data = useStaticQuery(graphql`
    {
      logo: datoCmsAsset(filename: { eq: "logo.svg" }) {
        id
        filename
        url
      }
      brands: allDatoCmsBrand {
        edges {
          node {
            __typename
            name
            slug
          }
        }
      }
    }
  `)
  data.brands.edges.map(({ node }) => console.log("node =>", node))
  return (
    <>
      <S.Container as="header" light={light} sticky={sticky}>
        {!backOnly && (
          <>
            <S.MenuWrapper>
              <S.Links>
                <S.MenuDropdown>
                  <Link to="/#brands-showcase">brands</Link>
                  <S.MenuDropdownList>
                    {data.brands.edges
                      .sort((a, b) =>
                        a.node.name.toLowerCase() > b.node.name.toLowerCase()
                          ? 1
                          : -1
                      )
                      .map(({ node }, i) => (
                        <Link
                          to={`/brand/${node.slug}`}
                          style={{ transitionDelay: i * 0.025 + "s" }}
                        >
                          {node.name}
                        </Link>
                      ))}
                  </S.MenuDropdownList>
                </S.MenuDropdown>
                <div>
                  <Link to="/#services">services</Link>
                </div>
                <div>
                  <Link to="/#founders">founders</Link>
                </div>
              </S.Links>
              {/*<S.HamburgerWrapper
                className="icon"
                onClick={() => setMenuOpen(s => !s)}
              >
                <span>{menuOpen ? "close" : "menu"}</span>
                    </S.HamburgerWrapper>*/}
            </S.MenuWrapper>
            <S.ContactWrapper>
              <button onClick={() => setModalOpen(true)}>contact</button>
            </S.ContactWrapper>
          </>
        )}
        <S.LogoWrapper onClick={() => navigate("/")}>
          <SVG src={data.logo.url}>
            <img src={data.logo.url} />
          </SVG>
        </S.LogoWrapper>
      </S.Container>
      {modalOpen && <ContactUsModal onClose={() => setModalOpen(false)} />}
    </>
  )
}

export default Header
