import { createGlobalStyle } from "styled-components"

const Global = createGlobalStyle`

  html {
    scroll-behavior: smooth;
  }
  body {
    color: #171717;
    transition: background 950ms ease-out;
    background: #e5e5e5;
    font-family: "Theinhardt Pan", sans-serif;
  }
  * {
    -webkit-tap-highlight-color: transparent;
  }
  
  .ReactModal__Body--open,
  .ReactModal__Html--open {
    overflow: hidden;
  }
  
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
    background: linear-gradient(
      ${props =>
        props.linearBg?.map(c => c.color && `${c.color.hex} ${c.stop}%`).join(", ")}
    ) !important;
  }
  
  .ReactModal__Overlay--after-open{
      opacity: 1;
  }
  
  .ReactModal__Overlay--before-close{
      opacity: 0;
  }
  
  .ReactModal__Content {
    transition: transform 400ms ease-out;
    transform: translateY(100vh);
  }
  
   .ReactModal__Content--after-open {
    transform: translateY(0);
  }
  .ReactModal__Content--before-close {
    transform: translateY(100vh);
  }


`

export default Global
