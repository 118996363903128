import React from "react"
import GridLayout from "../../layout/GridLayout"
import device from "../../util/devices"
import styled, { css } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import DatoCmsText from "../DatoCmsText"
import * as S from "./styled"

const Container = styled(GridLayout)`
  font-size: 14px;
  line-height: 20px;
  a {
    color: inherit;
    text-decoration: underline;
  }
  transition: color 550ms ease-out;
  ${props =>
    props.light &&
    css`
      color: white;
    `};
`

const Footer = ({ sticky, light }) => {
  const data = useStaticQuery(graphql`
    {
      contact: datoCmsContactDetail {
        email
        address
        phone
        socialMedia {
          url
          name
        }
      }
    }
  `)
  return (
    <S.FooterWrapper>
      <S.FooterLines>
        <Container light={light}>
          {/* <S.FooterColumn dangerouslySetInnerHTML={{__html: data.contact.address.replace(/<br\s*?\/?>/g, " ")}} /> */}
          <S.FooterColumn>
            Keizersgracht 206<br />
            1016 DX Amsterdam
          </S.FooterColumn>
          <S.FooterColumn>
            <a href="mailto:hello@phasetwo.agency">hello@phasetwo.agency</a>
            <a href="https://www.instagram.com/phasetwo.agency/" target="_blank">Instagram</a>
          </S.FooterColumn>
          <S.FooterColumn>
            Noortje Siecker<br />
            +31 6 27 38 36 50
          </S.FooterColumn>
          <S.FooterColumn>
            Michel Bos<br />
            +31 6 55 85 38 32
          </S.FooterColumn>
          {/* <div>
            <a href={`mailto:${data.contact.email}`}>{data.contact.email}</a>
            <br /> {data.contact.phone}
          </div>
          <DatoCmsText
            WrapperComponent={Address}
            text={data.contact.address.replace(/<br\s*?\/?>/g, " ")}
          />
          <div id="social">
            {data.contact.socialMedia.map((sm, i) => (
              <React.Fragment key={i}>
                <a href={sm.url}>{sm.name}</a>
                <br />
              </React.Fragment>
            ))}
          </div> */}
        </Container>
      </S.FooterLines>
      <Container light={light}>
        <S.Legal>Copyright © 2023  -  Phase Two</S.Legal>
      </Container>
    </S.FooterWrapper>
  )
}

export default Footer
