import React, { useEffect, useState } from "react"
import { StyleSheetManager } from "styled-components"
import griddie from "styled-griddie"
import { ParallaxProvider } from "react-scroll-parallax/cjs"
import Global from "../global-styles"
import { Normalize } from "styled-normalize"
import Header from "./Header"
import BackgroundContext from "../context/BackgroundContext"
import MobileMenu from "./MobileMenu"
import Footer from "./Footer"
import { navigate } from "gatsby"
import PreLoader from "./PreLoader"

export default props => {
  // TODO rename this context + variables
  const [linearBackgroundColors, setLinearBackgroundColors] = React.useState(
    null
  )

  const [menuOpen, setMenuOpen] = React.useState(false)
  useEffect(() => setMenuOpen(false), [props.location.href])

  const [loading, setLoading] = useState(true)

  return (
    <BackgroundContext.Provider
      value={{ linearBackgroundColors, setLinearBackgroundColors }}
    >
      <StyleSheetManager stylisPlugins={[griddie]}>
        <ParallaxProvider>
          <Global linearBg={linearBackgroundColors} />
          <Normalize />
          <MobileMenu open={menuOpen} />
          <Header
            sticky={!linearBackgroundColors}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            light={!!linearBackgroundColors}
            backOnly={!!linearBackgroundColors}
            onBack={() =>
              props.location.previous ? navigate(-1) : navigate("/")
            }
          />
          {loading &&
            !props.location.previous &&
            props.location.pathname === "/" && (
              <PreLoader onFaded={() => setLoading(false)} />
            )}
          {props.children}
          {!props.data?.datoCmsBrand && (
            <Footer
              sticky={!linearBackgroundColors}
              light={linearBackgroundColors}
            />
          )}
        </ParallaxProvider>
      </StyleSheetManager>
    </BackgroundContext.Provider>
  )
}
