import styled, { css } from "styled-components"
import GridLayout from "../../layout/GridLayout"
import device from "../../util/devices"

export const Container = styled(GridLayout)`
  padding-top: 10px;
  z-index: 30;
  ${props =>
    props.sticky &&
    css`
      position: sticky;
      top: 0;
    `}

  @media (${device.tablet}) {
    padding-top: 20px;
  }

  transition: color 500ms ease-out;
  ${props =>
    props.light &&
    css`
      color: white;
    `}
`

export const LogoWrapper = styled.div`
  grid-row: 1;
  grid-column: 2 / 6;
  margin: 0 auto;
  cursor: pointer;
  svg {
    height: auto;
    width: clamp(104px, ${(104 / 390 * 100).toFixed(2)}vw, 130px);
  }
  @media (${device.desktop}) {
    grid-column: 5 / 9;
  }
`

export const MenuWrapper = styled.div`
  font-size: 13px;
  line-height: 20px;
  align-self: center;

  grid-column: 6;
  grid-row: 1;
  justify-self: end;
  @media (${device.desktop}) {
    grid-column: 1 / 5;
    justify-self: start;
  }

  .links {
    a {
      letter-spacing: 0.03em;
      grid-row: 1;
      grid-column: 3;
      color: inherit;
      text-decoration: none;
    }
    @media (${device.phone}) {
      display: none;
    }
  }

  .icon {
    @media (${device.desktop}) {
      display: none;
    }
  }
`
export const Links = styled.div`
  padding-right: 20px;
  a {
    font-size: 16px;
    letter-spacing: 0.03em;
    grid-row: 1;
    grid-column: 1;
    color: inherit;
    text-decoration: none;
  }
  display: none;
  @media (${device.desktop}) {
    display: flex;
  }
  > :first-child {
    margin-right: 20px;
  }
  > :nth-child(2) {
    margin-right: 20px;
  }
`
export const HamburgerWrapper = styled.div`
  align-self: center;
  cursor: pointer;

  display: flex;
  align-items: start;

  span {
    font-size: 16px;
    line-height: 20px;

    letter-spacing: 0.03em;
    margin-left: 7px;
  }
`

export const ContactWrapper = styled.div`
  grid-row: 1;
  display: none;
  button {
    -webkit-appearance: none;
    padding: 0;
    border: 0;
    outline: 0;
    color: inherit;
    cursor: pointer;
    background: transparent;
  }
  @media (${device.desktop}) {
    display: block;
    grid-column: 10 / 13;
    align-self: center;
    justify-self: end;
  }
  a {
    color: inherit;
    text-decoration: none;
    font-size: 16px;
  }
`

export const BackWrapper = styled.div`
  grid-row: 1;
  grid-column: 6;
  padding-top: 10px;
  font-size: 13px;
  line-height: 20px;
  text-align: right;
  padding-right: 10px;
  cursor: pointer;

  letter-spacing: 0.03em;

  @media (${device.desktop}) {
    display: none;
  }
`

export const MenuDropdownList = styled.div`
  position: absolute;
  opacity: 0;
  display: flex;
  flex-direction: column;
  gap: 0.25em;
  top: 100%;
  left: -1.5em;
  z-index: 1;
  pointer-events: none;
  transition: opacity 0.6s ease-in;
  padding: 0.75em 1.5em 1.5em 1.5em;
  width: max-content;
  a {
    opacity: 0.5;
    transform: translateY(1em);
    transition: transform 0.6s ease-in;
    &:hover {
      opacity: 1;
    }
  }
`

export const MenuDropdown = styled.div`
  position: relative;
  z-index: 100;
  &:before {
    display: block;
    content: '';
    position: absolute;
    left: -1.5em;
    top: -1em;
    right: -1.5em;
    bottom: -1em;
  }
  > a {
    position: relative;
    z-index: 10;
  }
  &:hover {
    ${MenuDropdownList} {
      opacity: 1;
      transform: translateY(0em);
      pointer-events: auto;
      transition: opacity 0.6s ease;
      > a {
        transition: transform 0.6s cubic-bezier(0.1,0.7,0.3,1);
        transform: translateY(0em);
      }
    }
  }
`