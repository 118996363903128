import React, { useEffect, useRef, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { ImageCaption } from "../Texts"
import * as S from "./styled"

const PreLoader = ({ onFaded }) => {
  const [fading, setFading] = useState(false)
  const { data } = useStaticQuery(graphql`
    {
      data: datoCmsLoadingScreen {
        id
        duration
        image {
          width
          height
          alt
          title
          url
        }
      }
    }
  `)
  const imageRef = useRef(null)
  useEffect(() => {
    if (imageRef.current.complete) {
      wait()
    } else {
      imageRef.current.onload = wait
    }
  })
  const wait = () =>
    setTimeout(() => {
      setFading(true)
      setTimeout(() => onFaded && onFaded(), 450)
    }, data.duration * 1000)
  return (
    <S.Overlay className={fading ? "fading" : null}>
      <S.Container>
        <img ref={imageRef} src={data.image.url} alt={data.image.alt} />
        {/* <ImageCaption>{data.image.title}</ImageCaption> */}
      </S.Container>
    </S.Overlay>
  )
}

export default PreLoader
