import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Body3 } from "../Texts"
import DatoCmsText from "../DatoCmsText"
import * as S from "./styled"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

const ContactUsModal = ({ onClose }) => {
  const data = useStaticQuery(graphql`
    {
      logo: datoCmsAsset(filename: { eq: "logo.svg" }) {
        id
        filename
        url
      }
      contact: datoCmsContactDetail {
        address
        financialInformation
        email
        socialMedia {
          name
          url
        }
        contacts {
          email
          name
          phone
        }
      }
    }
  `)
  useEffect(() => {
    const reactModalContent = document.getElementsByClassName(
      "ReactModal__Content"
    )[0]
    disableBodyScroll()
    if (reactModalContent) {
      reactModalContent.style.overflow = "hidden"
      reactModalContent.scrollTo({ top: 0 })
    }
    return () => {
      enableBodyScroll()
      if (reactModalContent) reactModalContent.style.overflow = "auto"
    }
  })
  return (
    <S.Overlay>
      <S.Body>
        <S.Logo src={data.logo.url} />
        <S.CloseButton onClick={() => onClose && onClose()}>
          Close
        </S.CloseButton>
        <S.Divider row={2} />
        <S.Address WrapperComponent={Body3} text={data.contact.address} />
        <S.EmailAndSocialMedia>
          <a href={`mailto:${data.contact.email}`}>{data.contact.email}</a>
          <br />
          <br />
          {data.contact.socialMedia.map(s => (
            <a key={s.name} href={s.url} rel="noreferrer" target="_blank">
              {s.name}
            </a>
          ))}
        </S.EmailAndSocialMedia>
        <DatoCmsText
          WrapperComponent={S.FiscalInfo}
          text={data.contact.financialInformation}
        />
        <S.Divider row={4} />
        {data.contact.contacts.map(c => (
          <S.Contact as="p" key={c.name}>
            {c.name}
            <br />
            <a href={c.email}>{c.email}</a> <br />
            {c.phone}
          </S.Contact>
        ))}
      </S.Body>
    </S.Overlay>
  )
}

export default ContactUsModal
