import React from "react"

const DatoCmsText = ({ text, WrapperComponent, ...props }) => (
  <WrapperComponent
    {...props}
    dangerouslySetInnerHTML={{
      __html: text,
    }}
  />
)

export default DatoCmsText
